

header {
  padding-top: 7rem;
  min-height: 105vh;
  margin-bottom: 2rem;
  
  background-image: radial-gradient(circle at 10% 0%, rgba(55, 2, 202, 0.599) 5%, rgba(211, 211, 211, 0) 20%), radial-gradient(circle at 100% 50%, rgba(149, 1, 90, 0.599) 5%, rgba(211, 211, 211, 0) 20%),
  radial-gradient(circle at 30% 75%, rgba(1, 255, 251, 0.232) 0%, rgba(0, 0, 0, 0) 15%);
;
}

.header-container {
  position: relative;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  transform: translateY(-5%);
}

.btns {
  margin: 7rem 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
.btns.header {
  opacity: 0;
  animation: bringBtn 2s linear forwards 3.7s;
}
.header-socials {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: absolute;
  left: -50%;
  bottom: 3rem;
  font-size: 2rem;
  animation: slideSocial 3.1s linear forwards 0.7s;
}

@keyframes slideSocial {
  to {
    left: 0;
  }
}

.header-socials::after {
  content: "";
  width: 1px;
  height: 3rem;
  background: var(--color-primary);
}

.scroll-down {
  position: absolute;
  bottom: 5rem;
  right: 0;
  color: aliceblue;
}

@media screen and (max-width: 600px) {
  .scroll-down {
    display: none;
  }
  header {
    padding-top: 0;
  }

  .header-socials {
    flex-direction: row;
    left: 0;
    margin: 50%;
    opacity: 0;
    transform: translate(-50%, 100%);
    animation: bringBtn 3s linear forwards 3.7s;
  }

  .btns {
    margin: 9rem 3rem;
  }
}

@keyframes bringBtn {
  to {
    opacity: 1;
  }
}
