footer {
  display: flex;
  padding: 4rem 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: radial-gradient(circle at 0% 100%, rgb(31, 3, 91) 0%, rgba(5, 21, 115, 0.517) 3%, rgba(38, 255, 0, 0) 20%);
}

.footer-socials {
  display: inline-block;

  display: flex;
  flex-direction: row;
  width: fit-content;
}

.footer-socials a {
  font-size: 2rem;
  padding: 0.35rem 0.7rem;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.footer-logo {
  height: 150px;
  width: 300px;
  overflow: hidden;
}

.footer-logo img {
  height: 400px;
  transform: translateY(-30%);
}
