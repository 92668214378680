#about{
  background-image:radial-gradient(circle at 0% 50%,rgba(255, 0, 230, 0.684) 0%,rgba(255, 0, 238, 0.355) 5%, rgba(0, 0, 0, 0) 16%);

}
.about-container {
  height: 50vh;
  background:transparent;

  display: grid;
  
  gap: 15%;
  grid-template-columns: 20% 60%;
  border-right: 4px solid rgb(0, 148, 128);
 
}

#about h2 {
  padding: 0;
}

.aboutimage {
  height: 350px;
  width: 300px;
  border: 10px solid rgba(255, 255, 255, 0.363);
  border-radius: 30%;
  overflow: hidden;
  align-items: center;
}

.about-me {
  border-radius: 0;
  display: grid;
  place-items: center;
}

.about-me.info {
  font-size: 1.3rem;
  width: auto;
  border-radius: 0;
  font-weight: 250;
  color: rgb(255, 255, 255);
}

.about-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.about-card:hover {
  background: transparent;
  background: var(--color-primary-varient);
  cursor: default;
}

.Red {
  color: rgb(0, 255, 213);
}

.about-icon {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.about-card h2 {
  color: var(--color-bg);
}

.about-content p {
  margin: 2rem 0;
  border: 3px solid rebeccapurple;
}

@media screen and (max-width: 1024px) {
  .about-container {
    grid-template-columns: 1fr;
    height: auto;
    gap: 0;
  }

  .aboutimage {
    margin: 34px auto;
  }
}

@media screen and (max-width: 600px) {
  .about-me {
    width: 80%;
    margin: 0 auto 3rem;
    text-align: left;
    font: 500;
  }

  .aboutimage {
    border-radius: 50%;
  }

  .about-me.info p {
    font-size: 1rem;
  }
}
