@import url("https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

:root {
  --color-bga: #1e132c;
  /* --color-bg: linear-gradient(
    rgb(41, 1, 28),
    rgb(1, 1, 35),
    rgb(53, 0, 42),
    rgb(35, 13, 31),
    rgb(44, 1, 27),
    rgb(11, 7, 18),
    rgb(43, 0, 42),
    rgb(37, 1, 37),
    rgb(28, 2, 49)
  ); */
  --color-bg: linear-gradient(
    rgb(0, 0, 0),
    rgb(7, 0, 70),
    rgb(0, 0, 0),
    rgb(0, 0, 0),
    rgb(30, 0, 24),
    rgb(11, 7, 18),
    rgb(0, 0, 0),
    rgb(90, 2, 90),
    rgb(0, 0, 43)
  );
  --border-col: 6px solid rgba(100, 99, 99, 0.419);
  --color-bg-varient: rgb(61, 0, 61);
  --color-primary: #c3c3c3;
  --color-primary-varient: rgb(215 125 162);
  --color-white: white;
  --color-light: #b29d9d;
  --container-width-lg: 75%;
  --contaier-width-md: 86%;
  --container-width-ms: 90%;
  --transition: all 450ms ease;
  --main-col: rgb(0, 255, 213);
  --text-col: rgba(110, 22, 118, 0.877);
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Outfit", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.6;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h2 {
  text-transform: uppercase;
}

h1 {
  font-size: 2.3rem;
}

section {
  padding-top: 4rem;
  margin-top: 8rem;
  height: auto;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  padding-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  color: rgb(255, 255, 255);
  display: inline-block;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bga);
  border-color: transparent;
}

.btn-primary {
  color:rgb(0, 0, 0);
 
  background: rgb(60, 255, 1);
  border: transparent;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--contaier-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-ms);
  }

  section {
    height: auto;
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}

svg .draw {
  transform: translateX(-5%);
  stroke: #000;
  fill: transparent;
  stroke-dasharray: 1000;
  animation: 5s linear infinite;
  stroke-dashoffset: 3000;
}
