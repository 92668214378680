nav {
  background: rgba(211, 196, 196, 0);
  position: fixed;
  z-index: 400000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 0.5rem 2rem;
  width: 100%;
  gap: 1.4rem;
  border-radius: 1rem;

  backdrop-filter: blur(1px);
}

nav a {
  background: transparent;
  height: auto;
  padding: 1rem;
  font-size: 1.4rem;
  color: var(--color-light);
}

nav a:hover {
  color: rgba(87, 69, 148, 0.927);
}

.logo {
  width: 80%;
  height: 80px;
  overflow: hidden;
}

.logo img {
  height: 400%;
  width: 200px;
  transform: translate(-40%, -40%);
  mix-blend-mode: color-dodge;
}

nav a.active {
  color: var(--color-white);
}

@media screen and (max-width: 600px) {
  nav {
    display: none !important;
  }
}
